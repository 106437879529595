import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: 'index' */ '../admin/login')
const Home = () => import(/* webpackChunkName: 'home' */ '../admin/home')
const Configuration = () => import(/* webpackChunkName: 'configuration' */'../admin/configuration')
const list = () => import(/* webpackChunkName: 'home' */ '../admin/list')
const user = () => import(/* webpackChunkName: 'home' */ '../admin/user')
const Region = () => import(/* webpackChunkName: 'Region' */ '../admin/Region')
const department = () => import(/* webpackChunkName: 'department' */ '../admin/department')
const Template = () => import(/* webpackChunkName: 'Template' */ '../admin/Template')
const CustomDetails = () => import(/* webpackChunkName: 'Template' */ '../admin/customDetails')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: 'login' },
  { path: '/home', redirect: 'list' },
  { path: '/login', name: 'login', component: Login },
  {
    path: '/home', name: 'home', component: Home, children: [
      { path: '/configuration', name: 'configuration', component: Configuration },
      { path: '/list', name: 'list', component: list },
      { path: '/user', name: 'user', component: user },
      { path: '/region', name: 'region', component: Region },
      { path: '/department', name: 'department', component: department },
      { path: '/template', name: 'template', component: Template },
      { path: '/customdetails', name: 'customdetails', component: CustomDetails },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 路由拦截
  // from从哪里来
  // to到哪去
  // next() 放行
  // console.log(to, from)
  // 只要不是去 Login 登录页 就判断是否带有token
  // 先拿到 token
  const token = localStorage.getItem('token')
  if (to.path === '/login' || token) {
    next()
  } else {
    alert('请先登录')
    setTimeout(() => {
      next('/login')
    }, 1000)
  }
})

export default router
